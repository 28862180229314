const Bigcard = ({texttitle, textcontent}) => {
    const contentItems = textcontent.split('\n').filter(item => item.trim() !== '');

    return(
        <div className='bg-customBlue w-80 h-76 flex flex-col gap-8 rounded-lg'>
            <div className="text-center bg-white text-customBlue text-2xl font-semibold border border-customBlue rounded-lg mt-1 ml-1 mr-1">
                <p>{texttitle}</p>
            </div>
            <div className="p-4 h-60 w-50 justify-center items-center">
                <ul className='list-disc list-inside text-white leading-tight text-lg'>
                {contentItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Bigcard;