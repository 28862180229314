import Bigcard from "./big-card";

const Services = () => {
    return(
        <div className="flex flex-col justify-center items-center gap-y-10 mb-10">
            <div>
                <h1 className="text-4xl mt-10 font-semibold">Our Services</h1>
            </div>
            <div className="flex flex-col gap-10 justify-center items-center lg:flex-row lg:items-stretch">
                <Bigcard
                texttitle={'IT Services'}
                textcontent={`IT and Consulting Services\n
                Web Development\n
                Android Development\n
                IT Support Services\n
                Salesforce CRM Support\n
                SAP RICEFW Development\n
                Cloud support`}
                />

            <Bigcard 
                texttitle={'Hardware Services'}
                textcontent={`Computer Hardware Networking \n
                Windows Server Management & installation\n
                CCTV installation\n
                Intercom installation\n
                Laptop, computer, printer repair\n
                projector installation and rental services`}
                />

            <Bigcard 
                texttitle={'3D Services'}
                textcontent={`3d Animation \n
                3d visualisation \n
                CGI product advertisement \n
                Rendering\n
                Video editing\n
                Rigging\n
                Architecture visualisation\n
                Product rendering`}
                />

            </div>
        </div>
    );
}

export default Services;