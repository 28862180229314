import Footername from '../assets/footer-name.svg';
import Footerig from '../assets/footer-ig-logo.svg';
import Footermail from '../assets/footer-mail-logo.svg';
import Footerlinkedin from '../assets/footer-linkedin-logo.svg';
import Footerphone from '../assets/footer-phone-logo.svg';

const Footer = () => {
    return(
        <div className='bg-customBlue flex flex-col justify-between p-8 lg:flex-row lg:items-stretch gap-10'>
            <img src={Footername} alt='nofootername'></img>
            <div className='flex justify-center items-center gap-10'>
                <a href='https://www.instagram.com/gizmo.innovations?igsh=MTQxbzczYW1oZmYzNg=='><img src={Footerig} alt='noimg'></img></a>
                <a href='admin@gizmoinnovations.in'><img src={Footermail} alt='noimg'></img></a>
                <a href='https://www.linkedin.com/company/gizmo-innovations/about/'><img src={Footerlinkedin} alt='noimg'></img></a>
                <a href='+91 8830468686'><img src={Footerphone} alt='noimg'></img></a>
            </div>
        </div>
    );
}

export default Footer;