import React, { useState } from 'react';
import Blacklogo from '../assets/black_logo.svg';
// import Yellowcircle from '../assets/yellow_circle.svg';
import './sample.css';
import {Menu, X} from "lucide-react";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return(
        <>
        <div className='flex justify-between m-10'>
            <img src={Blacklogo} alt='nologo'></img>
            <div className='md:flex text-2xl gap-10 font-inria-sans-bold hidden'>
                <a href='#main-section' className='p-8 hover:scale-125 duration-300 hover:text-customYellow'>Home</a>
                <a href='www.google.com'className='p-8 hover:scale-125 duration-300 hover:text-customYellow'>About</a>
                <a href='www.google.com'className='p-8 hover:scale-125 duration-300 hover:text-customYellow'>Services</a>
            </div>
            <div className='md:hidden flex justify-center'>
                <button onClick={toggleNavbar}>
                    {isOpen ? <X /> : <Menu />}
                </button>
            </div>
        </div>
        {isOpen && (
            <div className='flex flex-col items-center basis-full text-2xl'>
                <a href='www.google.com' className='cursor-pointer p-8 hover:scale-125 duration-300 hover:text-customYellow'>Home</a>
                <a href='www.google.com'className='p-8 hover:scale-125 duration-300 hover:text-customYellow cursor-pointer'>About</a>
                <a href='www.google.com'className='p-8 hover:scale-125 duration-300 hover:text-customYellow cursor-pointer'>Services</a>
            </div>
        )}
        </>
    );
}

export default Navbar;