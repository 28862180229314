import './App.css';
import Navbar from './components/navbar';
import Hero from './components/main-section';
import About from './components/about-section';
import Services from './components/services';
import Partnersec from './components/partners';
import Footersec from './components/footer';

function App() {
  return (
    <div className=' w-full'>
      <div>
        <Navbar />
        <Hero />
      </div>
      <div>
      <About />
      </div>
      <div>
      <Services />
      </div>
      <div>
        <Partnersec />
      </div>
      <div>
        <Footersec />
      </div>
    </div>
  );
}

export default App;
