 import Maintext from '../assets/major-text.svg';
 
 const Hero = () => {
    return(
        <div className='flex justify-center'>
            <img src={Maintext} alt='noimg' className='sm:p-10'></img>
        </div>
    );
 }

 export default Hero;