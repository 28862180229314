import Partnerlogo from '../assets/b_partnerlogo.svg';

const Partner = () => {
    return(
        <div className='flex flex-col gap-10 justify-center items-center mb-10'>
            <h1 className='text-4xl mt-10 font-semibold'>Meet our business partner</h1>
            <img src={Partnerlogo} alt='nologo'></img>
        </div>
    );
}

export default Partner;