// import Bgsectwo from '../assets/bg-of-sec2.svg';
import Smallcard from "./small-card";
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';

const Aboutus = () => {
    return(
        <div className='bg-customYellow mt-10'>
            <div className="flex flex-col items-center text-center gap-y-10">
                <h1 className="text-4xl mt-10 font-semibold">About us</h1>
                <p className="md:text-2xl mt-10 mb-10 sm:text-lg">Gizmo Innovations offers cutting-edge<span className=" font-semibold"> IT services</span> and<br></br> 
                solutions, specializing in transforming businesses along<br></br> 
                with <span className=" font-semibold">3D and hardware services.</span> Our team of skilled artists<br></br> 
                specializes in <span className=" font-semibold">3D animation, modeling, and CGI.</span><br></br>Our dedicated 
                support team ensures your technology runs<br></br> smoothly, while our 
                hardware services keep you equipped<br></br> with the latest equipment. <br></br></p>
            </div>
            <div className="flex justify-center flex-col gap-10 items-center lg:flex-row pb-10">
                <Smallcard 
                    img={Icon1}
                    text={"Innovative Solutions"}
                    // className="sm:full"
                />
                <Smallcard 
                    img={Icon2}
                    text={"Dedicated Support"}
                    // className="sm:w-1/3"
                />
                <Smallcard 
                    img={Icon3}
                    text={"3D Experitise"}
                    // className="w-1/3"
                />
            </div>
        </div>
    );
}

export default Aboutus;